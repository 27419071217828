import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import {
  Stack,
  Button,
  Tooltip,
  Typography,
  IconButton,
  ToggleButton,
} from "@mui/material";
// utils
import { fDate } from "../../../../utils/formatTime";
// hooks
import useResponsive from "../../../../hooks/useResponsive";
// components
import Iconify from "../../../../component/iconify/Iconify";
import useToggle from "../../../../hooks/useToggle";
import ComplianceCalendarForm from "./ComplianceCalendarForm";

// ----------------------------------------------------------------------

const VIEW_OPTIONS = [
  { value: "dayGridMonth", label: "Month", icon: "ic:round-view-module" },
  { value: "timeGridWeek", label: "Week", icon: "ic:round-view-week" },
  { value: "timeGridDay", label: "Day", icon: "ic:round-view-day" },
  { value: "listWeek", label: "Agenda", icon: "ic:round-view-agenda" },
];

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  padding: theme.spacing(2.5),
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

// ----------------------------------------------------------------------

CalendarToolbar.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  onToday: PropTypes.func,
  onNextDate: PropTypes.func,
  onPrevDate: PropTypes.func,
  onChangeView: PropTypes.func,
  view: PropTypes.oneOf([
    "dayGridMonth",
    "timeGridWeek",
    "timeGridDay",
    "listWeek",
  ]),
};

export default function CalendarToolbar({
  date,
  view,
  onToday,
  onNextDate,
  onPrevDate,
  onChangeView,
}) {
  const isDesktop = useResponsive("up", "sm");

  const {
    toggle: openCompliance,
    onOpen: onOpenCompliance,
    onClose: onCloseCompliance,
  } = useToggle();

  return (
    <RootStyle>
      {isDesktop && (
        <Stack direction="row" spacing={0.5}>
          {VIEW_OPTIONS.map((viewOption) => (
            <Tooltip key={viewOption.value} title={viewOption.label}>
              <ToggleButton
                value={view}
                selected={viewOption.value === view}
                onChange={() => onChangeView(viewOption.value)}
                sx={{ width: 32, height: 32, padding: 0, border: 0 }}
              >
                <Iconify icon={viewOption.icon} width={20} height={20} />
              </ToggleButton>
            </Tooltip>
          ))}
        </Stack>
      )}

      <Stack direction="row" alignItems="center" spacing={2}>
        <IconButton onClick={onPrevDate}>
          <Iconify icon="eva:arrow-ios-back-fill" width={20} height={20} />
        </IconButton>

        <Typography variant="h5">{fDate(date)}</Typography>

        <IconButton onClick={onNextDate}>
          <Iconify icon="eva:arrow-ios-forward-fill" width={20} height={20} />
        </IconButton>
      </Stack>

      {isDesktop && (
        <Stack direction="row" alignItems="center" spacing={2}>
          <Button
            size="small"
            variant="outlined"
            color="inherit"
            onClick={onOpenCompliance}
            sx={{
              backgroundColor: "#3C4256 !important",
              color: "white !important",
              marginRight: ".5rem",
              "&:hover": {
                backgroundColor: "white !important",
                color: "black !important",
                outline: "2px solid #3C4256",
                outlineOffset: "-2px",
              },
            }}
          >
           Compliance Task
          </Button>
          <Button
            size="small"
            color="error"
            variant="contained"
            onClick={onToday}
          >
            Today
          </Button>
          <ComplianceCalendarForm
            open={openCompliance}
            onClose={onCloseCompliance}
          />
        </Stack>
      )}
    </RootStyle>
  );
}
